import { text } from "./text";
import "./universe";

const MESSAGES = [];

MESSAGES.push({
  delay: 0,
  text: "♡♡♡ Те сакам... ♡♡♡<hr/>",
});

MESSAGES.push({
  delay: 0,
  text: "Те сакам како првиот ден.",
});

MESSAGES.push({
  delay: 0,
  text: "Чек.. Не.",
});

MESSAGES.push({
  delay: 500,
  text: "Те сакам се повеќе и повеќе...",
});

MESSAGES.push({
  delay: 0,
  text: "Секоја секунда, секој миг.<hr/>",
});

MESSAGES.push({
  delay: 3000,
  text: "Со тебе секој момент ми е преубав.",
});

MESSAGES.push({
  delay: 0,
  text: "Секој ден посебен.",
});

MESSAGES.push({
  delay: 0,
  text: "Секој ден не сакам да заврши.",
});

MESSAGES.push({
  delay: 0,
  text:
    "Кога те нема се е <b class='sivo'>сиво</b>, <b class='ladno'>ладно</b> и ми фалиш бескрајно.<hr/>",
});

MESSAGES.push({
  delay: 3000,
  text: "Моите очи го знаат само твоето лице.",
});

MESSAGES.push({
  delay: 0,
  text: "Постојат само за да те гледаат тебе.",
});

MESSAGES.push({
  delay: 0,
  text: "Твојата насмевка.",
});

MESSAGES.push({
  delay: 0,
  text:
    "Твоите очи, <b class='glow'>најсјајни ѕзвезди</b> во центарот на мојот универзум.<hr/>",
});

MESSAGES.push({
  delay: 5000,
  text:
    "Нема visualizer VidsBeats за  <b class='bum'>чукањето што го правиш на моето срце.</b>",
});

MESSAGES.push({
  delay: 500,
  text: "Нема мерка што може да измери колку те сакам.",
});

MESSAGES.push({
  delay: 500,
  text:
    "Нема реченица што можам да ја сврзам од сите зборови до сега измислени да можам да ти опишам колку те сакам.",
});

MESSAGES.push({
  delay: 500,
  text:
    "Нема, како што мојата љубов за тебе нема крај.<hr/>",
});

MESSAGES.push({
  delay: 8000,
  text: "Не можев повеќе да направам, толку стигнав.",
});

MESSAGES.push({
  delay: 0,
  text: "Ќе ти надокнадам во живо ;)<hr/>",
});

MESSAGES.push({
  delay: 2000,
  text:
    "Ти си мојот универзум, моето маче, моето мече, моето <b class='glow'>Лино</b>.<br/>Те сакам само тебе, и секогаш ќе те сакам.",
});

MESSAGES.push({
  delay: 1000,
  text: "Засекогаш твој,<br/></br>",
});
MESSAGES.push({
  delay: 0,
  text: "- Илија <br/><br/><br/>",
});

MESSAGES.push({
  delay: 0,
  text: "P.S. Сега знаеш зошто бев така „поетички” настроен =)",
});

MESSAGES.push({
  delay: 0,
  text: "P.P.S. Мрдни го маусот :)",
});

function play() {
  text(MESSAGES);

  $("audio#song")[0].oncanplay = () => {
    $("audio#song")[0].play();
  };

  let createdAudio = false;
  const interval = setInterval(() => {
    if ($("audio#song").length > 0) $("audio#song")[0].play();
    if (!createdAudio && $(".bum").length > 0) {
      $("audio#beat")[0].play();
      $("canvas").addClass("bum");
      setTimeout(() => {
        $("audio#beat")[0].pause();
        $("audio#beat").remove();
      }, 5000);
      clearInterval(interval);
      createdAudio = true;
    }
  }, 100);
  let ignoreScroll = false;
  const scroller = setInterval(() => {
    ignoreScroll = true;
    window.scrollTo(0, $("#message").outerHeight());
  }, 100);

  let lastNegative = 0;
  let diff = 0;
  let lastY = window.pageYOffset;
  let scrollCleared = false;
  $(window).on("scroll", (e) => {
    diff = window.pageYOffset - lastY;
    lastY = window.pageYOffset;
    if (ignoreScroll) {
      ignoreScroll = false;
      return;
    }

    if (diff < 0 && lastNegative < 3) {
      lastNegative += 1;
      return;
    }

    if (!scrollCleared && diff < 0 && lastNegative) {
      console.log("CLEARR");
      clearInterval(scroller);
      scrollCleared = true;
    }
  });
}

$(".overlay button").click(() => {
  $(".overlay button").text("НАЈСИГУРНО!");

  setTimeout(() => {
    $(".overlay").fadeOut(1000, () => {
      $(".overlay").remove();
    });
    setTimeout(() => {
      play();
    }, 1000);
  }, 1000);
});
